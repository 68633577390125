import { Button, Image } from 'react-bootstrap';
import { useBlogContext } from '../../context/useBlogContext';
import BlogCarousel from './BlogCarousel';
import { useState, useEffect, useMemo } from 'react';

const BlogPage = () => {
  const { posts, loading, error } = useBlogContext();
  const [postsCards, setPostsCards] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const postsMapAll = useMemo(() => posts.map((post) => ({
    id: post.id,
    title: post.title,
    resume: post.resume,
    cardImgPath: post.cardImgPath
  })), [posts]);

  useEffect(() => {
    const postsToCards = postsMapAll.slice(0, 3);
    setPostsCards(postsToCards);
  }, [postsMapAll]);

  if (loading) {
    return <p>Cargando...</p>;
  }
  if (error) {
    return <p>Error al cargar los posts</p>;
  }

  const handleShowAll = () => {
    setPostsCards(postsMapAll);
    setDisabled(true);
  };

  return (
    <>
      <Image src='/images/blog/blog_banner_1.png' className='w-100'></Image>
      <section className='d-flex align-items-center w-100 bg-green-light-cu p-lg-5 py-lg-5 py-5 flex-column gap-5' id="blog">
        <BlogCarousel posts={postsCards} size="large" justify="center" />
        <div className='d-flex justify-content-center'>
          <Button style={{ backgroundColor: "#8FB93E", borderColor: "#8FB93E" }} className={` px-5 py-2 rounded-1 ` + (disabled ? 'd-none' : '')} onClick={handleShowAll} >Ver Todos</Button>
        </div>
      </section>
    </>
  );
}

export default BlogPage;