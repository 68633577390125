import { Routes , Route } from "react-router-dom";
import Home from '../../components/Home';
import Truck from '../../components/Truck';
import Cards from '../../components/Cards';
import Pvideo from '../../components/Pvideo';
import Review from '../../components/Review';
import Preguntas from '../../components/Faqs';
import Terms from '../../components/Terms';
import Gracias from '../../components/Gracias';
import BannerGarlasLLC from "../../components/BannerGarlasLLC/BannerGarlasLLC";

const HomeRoutes = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/terms" element={<TermsAndConditions />} />
    <Route path="/home" element={<HomePage />} />
    <Route path="/gracias" element={<ThankYouPage />} />
  </Routes>
);

const HomePage = () => (
  <>
    <Home />
    <Truck />
    <Cards />
    <Pvideo />
    <Review />
    <Preguntas />
    <BannerGarlasLLC />
  </>
);

const TermsAndConditions = () => <Terms />;

const ThankYouPage = () => <Gracias />;

export default HomeRoutes;