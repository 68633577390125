import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavComp from './components/Nav';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import HomeRoutes from './pages/Home/HomeRoutes';
import BlogsRoutes from './pages/blog/BlogRoutes';
import  ReactGA  from 'react-ga4';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    ReactGA.initialize('AW-16643628397');
  }, [])

  return (
    <Router>
      <NavComp />
      <Routes>
        <Route path="/blog/*" element={<BlogsRoutes />} />
        <Route path="/*" element={<HomeRoutes />} />
      </Routes>
      <Footer />
    </Router>
  );
}
export default App;
