import React, { useState } from 'react';
import { Container, Row, Col, Accordion} from 'react-bootstrap';
import '../preguntas.css';

const Terms = () => {
    const [activeKey, setActiveKey] = useState(null);

    const handleToggle = (key) => {
      setActiveKey(activeKey === key ? null : key);
    };
  
    return (
      <div className="faq-section" id='Terms'>
        <Container>
          <h1>Terminos y Condiciones</h1>
          <Row>
            <Col md={6}>
              <Accordion activeKey={activeKey} className='accordion'>
                <Accordion.Item eventKey="0" className='accordion'>
                  <Accordion.Header onClick={() => handleToggle('0')} className='accordion'>
                    Terminos y condiciones(español)
                  </Accordion.Header>
                  <Accordion.Body className='text-white'>
                  <p className='align-items-center ms-3'>Al contratar los servicios de tucarroporusa.com de manera escrita, telefónica u oral estarán regidos por el siguiente contrato y/o Terminos & Condiciones</p>
          <h2 className='align-items-center ms-3' id='derechos'>Derecho y deberes</h2>
          <p className='align-items-center ms-3'>(El "Contrato") realizado entre (“el cliente”) que contrata los servicios de GARLAS LLC, LLC. DBA Nomanejes.com- MC#1257-B U.S DOT# 2954847 (" El Agente de Transporte de Autos"). El Cliente y El Agente de Transporte de Autos acuerdan que:</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>1.</span>El cliente garantiza que es el propietario legal registrado del vehículo transportado (el "Vehículo"), o el Cliente ha sido debidamente autorizado por todos los propietarios para celebrar este Contrato para el transporte del Vehículo.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>2.</span>En caso de que el Cliente cancele este Contrato por cualquier motivo, el Cliente deberá pagar a El Agente un cargo por cancelación mínima de $150.00 además de cualquier otro monto adeudado conforme a este Contrato. Se puede aplicar una tarifa extra en caso de que el Cliente no esté disponible o no esté dispuesto a proporcionar el vehículo para el transporte a partir de la primera fecha de disponibilidad según lo indicado en el formulario de envío.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>3.</span>El cliente, al presentar el Vehículo al Agente o al Transportista, y el destinatario, tras la aceptación de la entrega por parte del cliente o su agente, será solidariamente responsable de todos y cada uno de los cargos adeudados a pagar a cuenta del envío. incluyendo, entre otros, sumas adelantadas o desembolsadas por El Agente o cualquiera de sus agentes a cuenta de dicho envío y cualquier costo de cobro, incluidos los costos y los honorarios razonables de los abogados.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>4.</span>El cliente no debe dejar pertenencias personales en el vehículo, excepto las que están adjuntas y son parte del Vehículo. El Agente no será responsable por la pérdida o daño de pertenencias personales, incluyendo, sin limitación, cualquier propiedad personal que no esté instalada de fábrica, que no forme parte del Vehículo</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>5.</span>A menos que el servicio haya sido prepago, o se hayan hecho otros arreglos, el Cliente deberá pagar todas las cantidades  COD, incluidos los cargos adicionales, en efectivo o fondos certificados. En el caso de que dichas formas de pago no estén disponibles en el momento de la entrega, el Cliente será responsable de todas y cada una de las tarifas de almacenamiento tasadas. Para realizar una recogida / entrega, el Cliente acepta reunirse con el transportista en un momento y lugar específicos si es necesario</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>6.</span>El cliente deberá pagar todos los costos, incluidos, entre otros, los costos de almacenamiento, remolque y entrega adicionales incurridos como resultado del incumplimiento por parte del Cliente de cualquier garantía u obligación bajo este Contrato. Firmar el conocimiento de embarque del Transportista en lugar de origen o el conocimiento de embarque del Transportista en el lugar de destino sin anotación del daño será una prueba de la entrega satisfactoria del Vehículo.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>7.</span>El Agente nunca es responsable del vehículo que se transporta.  La responsabilidad del Transportista por el Vehículo comienza cuando el conocimiento de embarque es emitido y firmado por el conductor y termina cuando el Vehículo se firma en el destino.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>8.</span>El Agente o cualquiera de sus agentes no será responsable de los daños, incluidos, entre otros, los siguientes, no causados ​​por su negligencia</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>A.</span> Daño causado por líquidos, ácidos, anticongelante del sistema de enfriamiento, lluvia industrial o daños causados ​​por los actos de Dios.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>B.</span>Daño que sea indetectable debido a la condición del vehículo o daño al vidrio causado por el uso normal y el uso de la carretera.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>C.</span>Fallas mecánicas en el ensamblaje del escape, el chasis, la alineación, el daño en los neumáticos, los descapotables blandos, la suspensión, la puesta a punto del motor o daños causados ​​por el desgarre o rotura de los amarres.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>D.</span>Gastos de alquiler de auto.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>E.</span>Daño resultante del del vehículo sobrecargado</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>F.</span>Daño al Vehículo debido a que no se puede  ser manejado, no enciende o apaga o tiene defectuosos los frenos, frenos de estacionamiento o caja de cambios.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>9.</span>La responsabilidad del Agente o cualquiera de sus agentes por negligencia que cause daños al Vehículo se limitará al monto pagado por el Cliente por el transporte del Vehículo.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>10.</span>El cliente será responsable de preparar el vehículo para el envío. Todas las piezas sueltas, accesorios frágiles o sobresalientes, spoilers bajos, antenas, etc., deben retirarse y / o asegurarse adecuadamente. Cualquier parte del vehículo que se cae durante el transporte es responsabilidad del cliente, incluidos los daños causados ​​a cualquier otro vehículo involucrado. Los sistemas de seguridad deben ser desarmados y cualquier llave o transmisor para dicho dispositivo debe ser provisto al transportador.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>11.</span>El Cliente acepta indemnizar, defender y mantener indemne a El Agente y sus agentes por los costos, gastos, daños, pérdidas y reclamaciones causados ​​por el incumplimiento por parte del Cliente de cualquier garantía u obligación bajo este Contrato.</p>
          <h2 className='align-items-center ms-3 mt-5 mb-3' id='procedimientos'>Procedimientos</h2>
          <p className='align-items-center ms-3'><span className='fw-bold'>12.</span>El Agente garantiza y el Cliente reconoce que  El Agente está autorizado por la Federal Motor Carrier Safety Administration. (FMCSA)</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>13.</span>El Agente de Transporte Automotor y el transportista del Vehículo están autorizados para operar y transportar el Vehículo desde el punto de origen hasta el destino especificado en el conocimiento de embarque del Agente. El Transportista está autorizado a conducir el Vehículo en el punto de origen o punto de destino entre los puntos de carga / descarga y los puntos de recogida / entrega, y el Cliente deberá proporcionar un seguro por el mismo.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>14.</span>En el caso de que haya retrasos imprevistos con respecto a la entrega, las regulaciones federales requieren que todos los gastos de flete pendientes se paguen sin deducciones. El Cliente acuerda anotar adecuadamente cualquier daño reclamado en el momento de la entrega del Vehículo, y pagar el saldo de los gastos de envío en efectivo o en fondos certificados. No se respetarán los daños no indicados en el conocimiento de embarque del agente de transporte (sin excepciones). Cualquier reclamo de daño causado por El Transportista  debe hacerse por escrito dentro de los 15 días posteriores a la entrega, especificando el daño reclamado. El transportista que  es el que realmente transporta el Vehículo será responsable de todos y cada uno de los reclamos por daños que surjan del transporte. El cliente acuerda hacer todos los reclamos con el transportista identificado en el recibo de entrega o recogida, y presentar cualquier acción legal por daños y perjuicios contra el transportista únicamente. El cliente acuerda liberar y mantener indemne a El Agente de tales reclamos.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>15.</span>Después de que el Cliente pone el Vehículo a disposición de El Transportista para el transporte, El Transportista hará todos los esfuerzos posibles para entregar el Vehículo de manera expedita. Sin embargo, El  Agente no garantiza la fecha o la hora de entrega</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>16.</span>El Cliente pagará $ 250 adicionales si el Vehículo es o deja de funcionar durante el transporte, y una cantidad adicional si el Vehículo está sobredimensionado o con sobrepeso, a menos que el Vehículo se revele como inoperable, sobredimensionado o con sobrepeso, respectivamente. Todos los vehículos inoperables deben gobernar, frenar y rodar.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>17.</span>Mientras El Agente o el Transportista  y sus agentes conducen el Vehículo con el propósito de estacionar, almacenar y otros propósitos relacionados con el cumplimiento de las obligaciones bajo este Contrato, El Agente o el Transportista tendrán el beneficio completo de cualquier seguro que se haya efectuado por el Cliente en el Vehículo, a menos que dicha cobertura de seguro sea nula mientras el vehículo o la propiedad esté en posesión de El  Agente o el Transportista y sus agentes.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>18.</span>El Cliente, en su ausencia, deberá designar a alguien para actuar como agente del Cliente en los puntos de recogida y / o entrega.</p>
          <h2 className='align-items-center ms-3 mt-5 mb-3'id='generales'>Dispociones Generales</h2>
          <p className='align-items-center ms-3'><span className='fw-bold'>19.</span>Este Contrato constituye el acuerdo completo entre las partes con respecto al tema del presente. Reemplaza todas las negociaciones previas, cartas y entendimientos relacionados con el tema del presente.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>20.</span>Este Contrato no puede ser enmendado, complementado o modificado en su totalidad o en parte, excepto por un instrumento por escrito firmado por la parte o partes contra las cuales se solicita la ejecución de cualquier enmienda, suplemento o modificación.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>21.</span>Este Contrato se interpretará de acuerdo con las leyes de Florida, sin la aplicación de sus principios de elección de ley.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>22.</span>No se honrará el alquiler de automóviles por demoras, accidentes, daños, fuerza mayor o cualquier otra razón.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>23.</span>Si se instituye una acción legal para hacer cumplir los términos y condiciones de este Contrato, la jurisdicción exclusiva y el lugar para dicha acción serán en los tribunales estatales y federales del Condado de Broward, Florida. Por la presente, las partes renuncian irrevocablemente, en la máxima medida permitida por la ley, (a) cualquier objeción a la jurisdicción o el lugar de cualquier acción que surja de o esté relacionada con este Contrato iniciada en el Condado de Broward, Florida, o cualquier sentencia emitida por cualquier tribunal en respecto de eso, o (b) cualquier reclamo de que cualquier acción entablada en Broward County, Florida, haya sido presentada en un foro inconveniente.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>24.</span>Ninguna persona, que no sea el Transportista y El Agente y sus agentes, se considerará que posee ningún derecho de beneficiario de terceros en virtud de este Contrato. Es la intención de las partes del presente contrato que la ejecución de este no implica ningún beneficio directo para ningún tercero, que no sea el Transportista y El Agente y sus agentes. El Transportista y El Agente y sus agentes son terceros beneficiarios expresos de los términos de este Contrato.</p>
          <p className='align-items-center ms-3 mb-3'>Este contrato reemplaza todas las representaciones escritas u orales previas de GARLAS LLC LLC. y constituye el acuerdo completo entre el cliente y GARLAS LLC LLC. y no puede ser cambiado excepto por escrito firmado por un oficial de GARLAS LLC LLC. El Cliente garantiza que él/ella ha leído este acuerdo en su totalidad y que al continuar con la transacción entiende y acepta sus términos.</p>
          <p className='align-items-center ms-3 mb-5'>Además, el Cliente renuncia a cualquier reclamación o defensa basada en no haber leido o entendido  total o parcialmente estos términos y condiciones, y acepta indemnizar y mantener indemne a GARLAS LLC LLC por cualquier honorario o costo, incluidos honorarios y costos de abogados, que surjan de cualquier reclamación o defensa afirmada basada en no leer, tener conocimiento de, o entender estos términos y condiciones. GARLAS LLC LLC.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col md={6}>
              <Accordion activeKey={activeKey}>
                <Accordion.Item eventKey="3">
                  <Accordion.Header onClick={() => handleToggle('3')}>
                    Terms and Conditions(English)
                  </Accordion.Header>
                  <Accordion.Body>
                  <p className='align-items-center ms-3'>By contracting the services of tucarroporusa.com in written, telephone or oral manner will be governed by the following agreement and/or Terms & Conditions</p>
          <h2 className='align-items-center ms-3' id='rights'>Rights and responsabilities</h2>
          <p className='align-items-center ms-3'>(the "Contract") is made between the client signing this Contract (the "Client") and GARLAS LLC, LLC. (DBA Nomanejes.com- MC#1257-B U.S DOT# 2954847 ("The Auto Transport Broker"). The Client and The Auto Transport Broker agree as follows:</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>1.</span>Client warrants that client is the registered legal owner of the vehicle being transported (the "Vehicle"), or that Client has been duly authorized by the legal owners to enter into this Contract for transportation of the Vehicle</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>2.</span>n the event that Client cancels this Contract for any reason whatsoever, Client shall pay The Auto Transport Broker a minimum cancellation charge of $150.00 in addition to any other amounts due under this Contract. A "dry run" fee may be assessed in the event Client is unavailable or unwilling to provide the Vehicle for transportation as of the first date of availability as designated on the shipping form.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>3.</span>Client, upon tender of the Vehicle to The Auto Transport Broker or its transportation agent, and the consignee, upon acceptance of delivery by Client or its agent, shall be jointly liable for any and all unpaid charges payable on account of the shipment, including but not limited to, sums advanced or disbursed by The Auto Transport Broker or any of its agents on account of such shipment and any and all costs of collection including costs and reasonable attorney’s fees</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>4.</span>Client shall not leave personal belongings in the Vehicle except those attached to and part of the Vehicle. The Auto Transport Broker shall not be responsible for loss of or damage to personal belongings, including without limitation any personal property which is not factory installed, that are not a part of the Vehicle.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>5.</span>Unless the order has been pre-paid, or other arrangements have been made, Client shall pay all COD amounts, including any additional charges, in cash or certified funds. In the event that said forms of payments are not available at delivery the Client shall be responsible for any and all storage fees assessed. In order to make pick up/delivery, the Client agrees to meet the carrier at a specified time and place if necessary.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>6.</span>Client shall pay and all costs, including without limitation storage, towing and additional delivery costs, incurred as a result of Client's breach of any warranty or  obligation under this Contract. Signing The Auto Transport Broker's bill of lading or its transportation agent’s bill of lading at destination without notation of damage shall be evidence of satisfactory delivery of the Vehicle</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>7.</span>The Auto Transport Broker is never liable for the vehicle being transported but the Carrier’s responsibility for the Vehicle commences when the bill of lading is issued and signed by the driver and terminates when the Vehicle is signed for at destination.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>8.</span>The Auto Transport Broker or any of its agents shall not be liable for damages, including without limitation any of the following, not caused by their negligence:</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>A.</span>Damage caused by fluids, acids, cooling system antifreeze, industrial fallout or damage caused by acts of God.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>B.</span>Damage that is undetectable due to Vehicle's condition or glass damage caused by normal wear and road use.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>C.</span>Mechanical malfunctions exhaust assembly, frame, alignment, tire damage, soft top convertibles, suspension, tuning of engine or damage that is a result of tie downs tearing or breaking.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>D.</span>Auto rental accruals.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>E.</span> Damage resulting from the Vehicle being overloaded.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>F.</span> Damage to the Vehicle because it cannot be driven on or off transporter under its own power or has defective or insufficient brakes, parking brake or parking gear.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>9.</span>The liability of The Auto Transport Broker or any of its agents for negligence causing damage to the Vehicle shall be limited to the amount paid by the Client for the transportation of the Vehicle.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>10.</span>The Client shall be responsible for preparing the Vehicle for shipment. All loose parts, fragile or protruding accessories, low hanging spoilers, antennas, etc., must be removed and/or properly secured. Any part of the Vehicle that falls off during transport is the Client's responsibility, including damages caused to any other vehicles involved. Security systems should be disarmed and any keys or transmitters for said device must be provided to the transporter</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>11.</span>The Client agrees to indemnify, defend and hold The Auto Transport Broker and its agents harmless for any costs, expenses, damage, losses and claims caused by the Client's breach of any warranty or obligation under this Contract</p>

          <h2 className='align-items-center ms-3 mt-5 mb-3' id='procedures'>Procedures</h2>
          <p className='align-items-center ms-3'><span className='fw-bold'>12.</span>The Auto Transport Broker warrants and Client acknowledges that The AutoTransport Broker is licensed by the Federal Motor Carrier Safety Administration</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>13.</span>The Auto Transport Broker and the hired Carrier transporting the Vehicle are hereby authorized to operate and transport the Vehicle from point of origin to the destination specified in Carrier's bill of lading. The Carrier and/or The Auto Transport Broker  are authorized to drive the Vehicle either at point of origin or point of destination between the points of loading/unloading and the points of pickup/delivery, and Client shall provide insurance for same.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>14.</span>In the event that there are any unforeseen delays regarding delivery, federal regulations require that all outstanding freight charges be paid without deductions. The Client agrees to properly note any damage claimed at the time of the delivery of the Vehicle, and to pay the balance of the delivery charges in cash or certified funds. Damages not noted on the transportation carrier's bill of lading will not be honored (no exceptions). Any claim of damage caused by The Carrier must be made within 15 days of delivery in writing, specifying the damage claimed. The carrier hired by The Auto Transport Broker actually transporting the Vehicle shall be liable for any and all damage claims arising from the transport. The Client agrees to file all claims with such Carrier transportation agent as identified on the transportation agent's bill of lading/delivery receipt, and to bring any legal action for damages against such transportation agent only.The Client agrees to release and hold harmless The Auto Transport Broker from any such claim</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>15.</span>After the Client makes the Vehicle available to The Carrier for transport, The Carrier shall use its best efforts to deliver the Vehicle in an expedient manner. However, The Auto Transport Broker does not guarantee the date or time of delivery</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>16.</span>The Client shall pay an additional $250 if the Vehicle is or becomes inoperable during transport, and an additional amount for if the Vehicle is oversized or overweight, unless the Vehicle is disclosed as being inoperable, oversized or overweight, respectively. All inoperable vehicles must steer, brake and roll.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>17.</span>While The Carrier or The Auto Transport Broker and its agents are driving the Vehicle for purposes of parking, storage and other purposes incidental to performance of the obligations under this Contract, The Auto Transport Broker or Carrier shall have the full benefit of any insurance that has been effected by Client on the Vehicle, unless said insurance coverage is void while the vehicle or property is in the possession of The Carrier or The Auto Transport Broker and its agents</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>18.</span>The Client shall, in its absence, designate someone to act as the Client's agent at the points of pick up and/or delivery</p>

          <h2 className='align-items-center ms-3 mt-5 mb-3'id='terms'>General Terms</h2>
          <p className='align-items-center ms-3'><span className='fw-bold'>19.</span>This Contract constitutes the entire agreement between the parties hereto with respect to the subject matter hereof. It supersedes all prior negotiations, letters and understandings relating to the subject matter hereof.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>20.</span>This Contract may not be amended, supplemented or modified in whole or in part except by an instrument in writing signed by the party or parties against whom enforcement of any such amendment, supplement or modification is sought.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>21.</span>This Contract will be construed in accordance with the laws of Florida, without application of its choice-of-law principles.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>22.</span>No auto rental will be honored for delays, accidents, damage, acts of God, or any other reason.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>23.</span>If legal action is instituted to enforce the terms and conditions of this Contract, exclusive jurisdiction and venue for any such action will be in the state and federal courts of  Broward County, Florida. The parties hereto hereby irrevocably waive, to the fullest extent permitted by law, (a) any objection to jurisdiction or venue of any action arising out of or relating to this Contract brought in Broward County, Florida, or any judgment entered by any court in respect thereof, or (b) any claim that any action brought in Boroward County, Florida, has been brought in an inconvenient forum.</p>
          <p className='align-items-center ms-3'><span className='fw-bold'>24.</span>No person, other than The Auto Transport Broker's transportation agent and other agents, shall be deemed to possess any third-party beneficiary right pursuant to this Contract. It is the intent of the parties hereto that no direct benefit to any third party, other than The Auto Transport Broker's transportation agent and other agents, is intended or implied by the execution of this Contract. The Auto Transport Broker's transportation agent and other agents are express third party beneficiaries of the terms of this Contract.</p>

          <p className='align-items-center ms-3 mb-3'>This contract supersedes all prior written or oral representation of GARLAS LLC LLC. and constitutes the entire agreement between customer and GARLAS LLC LLC ("The Auto Transport Broker") and may not be changed except in writing signed by an officer of GARLAS LLC LLC ("The Auto Transport Broker"),  Customer warrants that he, she, or it has read this agreement in its entirety and by continuing with the transaction fully understands and agrees to its terms.</p>
          <p className='align-items-center ms-3 mb-3'>Further, Customer waives any claims or defenses based in whole or in part on not reading, knowing, or understanding these terms and conditions and agrees to indemnify and hold harmless GARLAS LLC LLC  ("The Auto Transport Broker") for any fees or costs, including attorney’s fees and costs, arising out of any claims or defenses asserted based upon not reading, having knowledge of, or understanding these terms and conditions. GARLAS LLC LLC</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };
export default Terms;
