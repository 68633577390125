import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const QuoteHome = () => {
  const navigate = useNavigate();
  const envioDesdeRef = useRef(null);
  const hastaRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 2; // Now only 2 steps

  const [formData, setFormData] = useState({
    vehiculo: "",
    envioDesde: "",
    hasta: "",
    nombreApellido: "",
    email: "",
    telefono: "",
  });

  const [errors, setErrors] = useState({
    vehiculo: true,
    envioDesde: true,
    hasta: true,
    nombreApellido: true,
    email: true,
    telefono: true,
  });

  const [touched, setTouched] = useState({
    vehiculo: false,
    envioDesde: false,
    hasta: false,
    nombreApellido: false,
    email: false,
    telefono: false,
  });

  useEffect(() => {
    if (!window.google) return;

    initAutocomplete(envioDesdeRef.current, "envioDesde");
    initAutocomplete(hastaRef.current, "hasta");
  }, []);

  const initAutocomplete = (element, fieldName) => {
    if (!element) return;

    const autocomplete = new window.google.maps.places.Autocomplete(element, {
      types: ["geocode"],
      componentRestrictions: { country: ["us", "pr", "gt", "sv", "ni", "hn"] },
    });
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        setErrors((prev) => ({ ...prev, [fieldName]: true }));
        setTouched((prev) => ({ ...prev, [fieldName]: true }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [fieldName]: place.formatted_address,
        }));
        setErrors((prev) => ({ ...prev, [fieldName]: false }));
        setTouched((prev) => ({ ...prev, [fieldName]: true }));
      }
    });
  };

  const validateField = (name, value) => {
    const phoneRegex = /^\(\d{3}\)-\d{3}-\d{4}-?\d{0,4}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    switch (name) {
      case "vehiculo":
        return value.trim() !== "";
      case "nombreApellido":
        return value.trim() !== "";
      case "telefono":
        return phoneRegex.test(value);
      case "email":
        return emailRegex.test(value);
      default:
        return false;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let isValid = true;

    if (name === "telefono") {
        // Elimina todos los caracteres que no sean dígitos
        
        const digits = value.replace(/\D/g, "");

        // Limita la cantidad de dígitos a 15, permitiendo hasta 4 caracteres adicionales por los guiones
        const limitedDigits = digits.slice(0, 15);

        let formattedNumber = '';

        // Formatea el número con paréntesis y guiones
        if (limitedDigits.length <= 3) {
            formattedNumber = `(${limitedDigits}`;
        } else if (limitedDigits.length <= 6) {
            formattedNumber = `(${limitedDigits.slice(0, 3)})-${limitedDigits.slice(3)}`;
        } else if (limitedDigits.length <= 10) {
            formattedNumber = `(${limitedDigits.slice(0, 3)})-${limitedDigits.slice(3, 6)}-${limitedDigits.slice(6)}`;
        } else {
            formattedNumber = `(${limitedDigits.slice(0, 3)})-${limitedDigits.slice(3, 6)}-${limitedDigits.slice(6, 10)}-${limitedDigits.slice(10)}`;
        }
        // Limita la longitud total del campo a 16 caracteres (paréntesis, guiones y dígitos)
        formattedNumber = formattedNumber.substring(0, 19);

        isValid = validateField(name, formattedNumber);
        setFormData(prevData => ({ ...prevData, [name]: formattedNumber }));
    } else {
        isValid = validateField(name, value);
        setFormData(prevData => ({ ...prevData, [name]: value }));
    }

    setTouched(prev => ({ ...prev, [name]: true }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: !isValid }));
  };

  const nextStep = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const isCurrentStepValid = () => {
    switch (currentStep) {
      case 1:
        return !errors.envioDesde && !errors.hasta && !errors.vehiculo;
      case 2:
        return !errors.nombreApellido && !errors.email && !errors.telefono;
      default:
        return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Disable the button

    const { vehiculo, envioDesde, hasta, nombreApellido, email, telefono } =
      formData;
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fields: {
            TITLE: nombreApellido,
            EMAIL: [{ VALUE: email, VALUE_TYPE: "WORK" }],
            PHONE: [{ VALUE: telefono, VALUE_TYPE: "WORK" }],
            UF_CRM_1470686551: envioDesde,
            UF_CRM_1470686597: hasta,
            SOURCE_ID: "Solicitud de cotización TuCarroPorUSA",
            UF_CRM_1486142337: vehiculo,
            ASSIGNED_BY_ID: "2",
          },
        }),
      };

      const response = await fetch(
        "https://carmoversus.com/api/v1/lead",
        options
      );
      console.log(response.data);
      if (!response.ok) throw new Error("Network response was not ok");
      navigate("/gracias");

      resetFormStates();
    } catch (error) {
      console.error("There was an error adding the lead:", error);
    } finally {
      setIsSubmitting(false); // Re-enable the button in case of error
    }
  };

  const resetFormStates = () => {
    setFormData({
      vehiculo: "",
      envioDesde: "",
      hasta: "",
      nombreApellido: "",
      email: "",
      telefono: "",
    });
    setErrors({
      vehiculo: true,
      envioDesde: true,
      hasta: true,
      nombreApellido: true,
      email: true,
      telefono: true,
    });
    setTouched({
      vehiculo: false,
      envioDesde: false,
      hasta: false,
      nombreApellido: false,
      email: false,
      telefono: false,
    });
  };

  return (
    <Card className="px-3 px-md-5 rounded-4 quote-style">
      <Form onSubmit={handleSubmit} className="my-5">
        {currentStep === 1 && (
          <>
            <Form.Group className="mb-3" controlId="formVehiculo">
              <Form.Label className="fw-light text-uppercase text-white">
                Marca, Modelo y Año
              </Form.Label>
              <div className="d-flex justify-content-center px-4">
                <div
                  className={`bg-g200 text-black fw-bold px-3 me-2 rounded d-flex justify-content-center align-items-center`}
                >
                  1
                </div>
                <Form.Control
                  type="text"
                  name="vehiculo"
                  placeholder="Ej: Toyota Corolla 2024"
                  onChange={handleChange}
                  isInvalid={errors.vehiculo && touched.vehiculo}
                  className="rounded py-2"
                  style={{ minWidth: "100%" }}
                />
                <Form.Control.Feedback type="invalid" />
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEnvioDesde">
              <Form.Label className="fw-light text-uppercase text-white">
                Origen
              </Form.Label>
              <div className="d-flex justify-content-center px-4">
                <div className="bg-g200 text-black fw-bold px-3 me-2 rounded d-flex justify-content-center align-items-center">
                  2
                </div>
                <Form.Control
                  type="text"
                  ref={envioDesdeRef}
                  onFocus={() => {
                    setTouched({ ...touched, envioDesde: true });
                  }}
                  isInvalid={errors.envioDesde && touched.envioDesde}
                  className="rounded py-2 "
                  style={{ minWidth: "100%" }}
                />
                <Form.Control.Feedback type="invalid" />
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formHasta">
              <Form.Label className="fw-light text-uppercase text-white">
                Destino
              </Form.Label>
              <div className="d-flex justify-content-center px-4">
                <div className="bg-g200 text-black fw-bold px-3 me-2 rounded d-flex justify-content-center align-items-center">
                  3
                </div>
                <Form.Control
                  type="text"
                  ref={hastaRef}
                  onFocus={() => setTouched({ ...touched, hasta: true })}
                  isInvalid={errors.hasta && touched.hasta}
                  className="rounded py-2"
                  style={{ minWidth: "100%" }}
                />
                <Form.Control.Feedback type="invalid" />
              </div>
            </Form.Group>

            {currentStep < totalSteps && (
              <Button
                variant="primary"
                style={{ backgroundColor: "#8FB93E", borderColor: "#8FB93E" }}
                className="btn-continue w-100 mt-3 rounded px-5 py-button"
                onClick={nextStep}
                disabled={!isCurrentStepValid()}
              >
                Continue
              </Button>
            )}
          </>
        )}

        {currentStep === 2 && (
          <>
            <Form.Group className="mb-3" controlId="formNombreApellido">
              <Form.Label className="fw-light text-uppercase text-white">
                Nombre
              </Form.Label>
              <div className="d-flex justify-content-center px-4">
                <div className="bg-g200 text-black fw-bold px-3 me-2 rounded d-flex justify-content-center align-items-center">
                  4
                </div>
                <Form.Control
                  type="text"
                  name="nombreApellido"
                  placeholder="Ej: John Doe"
                  onChange={handleChange}
                  onBlur={() =>
                    setTouched({ ...touched, nombreApellido: true })
                  }
                  isInvalid={errors.nombreApellido && touched.nombreApellido}
                  className="rounded py-2"
                  style={{ minWidth: "100%" }}
                />
                <Form.Control.Feedback type="invalid" />
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label className="fw-light text-uppercase text-white">
                Email
              </Form.Label>
              <div className="d-flex justify-content-center px-4">
                <div className="bg-g200 text-black fw-bold px-3 me-2 rounded d-flex justify-content-center align-items-center">
                  5
                </div>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  onBlur={() => setTouched({ ...touched, email: true })}
                  isInvalid={errors.email && touched.email}
                  className="rounded py-2"
                  style={{ minWidth: "100%" }}
                />
                <Form.Control.Feedback type="invalid" />
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formTelefono">
              <Form.Label className="fw-light text-uppercase text-white">
                Teléfono
              </Form.Label>
              <div className="d-flex justify-content-center px-4">
                <div className="bg-g200 text-black fw-bold px-3 me-2 rounded d-flex justify-content-center align-items-center">
                  6
                </div>
                <Form.Control
                  type="tel"
                  name="telefono"
                  placeholder="(555)-123-4567"
                  value={formData.telefono}
                  onChange={handleChange}
                  onBlur={() => setTouched({ ...touched, telefono: true })}
                  isInvalid={errors.telefono && touched.telefono}
                  className="rounded py-2"
                  style={{ minWidth: "100%" }}
                />
                <Form.Control.Feedback type="invalid" />
              </div>
            </Form.Group>

            {currentStep === totalSteps && (
              <Button
                type="submit"
                variant="primary"
                style={{ backgroundColor: "#8FB93E", borderColor: "#8FB93E" }}
                className="btn-continue w-100 mt-3 rounded py-button"
                disabled={!isCurrentStepValid() || isSubmitting}
              >
                {isSubmitting ? "Enviando..." : "Solicitar cotización"}
              </Button>
            )}
          </>
        )}
      </Form>
    </Card>
  );
};

export default QuoteHome;
