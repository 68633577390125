import React, { useState } from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import '../preguntas.css';

const Preguntas = () => {
  const preguntasFrecuentes = [
    {
      title: '¿Prestan servicio fuera de USA, Puerto Rico y Canadá?',
      content: 'Sí, prestamos servicio en USA, Puerto Rico y Canadá.'
    },
    {
      title: '¿Cómo funciona el servicio?',
      content: 'Transportar tu carro es fácil, seguro y solo necesitas hacer 3 cosas: 1. Contactarnos al 1-870-455-6742 para hacerte una cotización/estimado. 2. Una vez apruebes tu estimado debes pagar tu reserva. 3. Esperas a recibir tu carro en el destino y pagas el total del servicio cotizado previamente. Nota: Si cancelas tu reserva, tendrá una penalidad de US$150.00.'
    },
    {
      title: '¿Qué necesito saber para entregar mi vehículo?',
      content: 'Para entregar tu vehículo, asegúrate de que esté limpio, con menos de un cuarto de tanque de gasolina y sin objetos personales dentro.'
    },
    {
      title: '¿Se pueden presentar retrasos o cambios en la recogida o en la entrega?',
      content: 'Aunque hacemos todo lo posible para cumplir con los horarios establecidos, pueden ocurrir retrasos o cambios debido a factores fuera de nuestro control.'
    },
    {
      title: '¿El precio cotizado puede variar?',
      content: 'Recuerda NO firmar ningún documento y debes llamarnos inmediatamente al 1-870-455-6742 si tu vehículo sufrió algún tipo de daño durante su traslado.'
    },
    {
      title: '¿Cuánto tiempo tarda la entrega de un vehículo?',
      content: 'El tiempo de entrega dependerá de la cantidad de millas recorridas y de algunas condiciones como el clima y el tráfico. Rutas entre 500 y 1,000 millas pueden tardar de 2 a 3 días. Rutas entre 1,000 y 2,000 millas pueden tardar de 4 a 7 días. Rutas de más de 2,000 millas pueden tardar entre 7 y 12 días.'
    },
    { 
      title: '¿Qué está cubierto por el seguro?',
      content: 'SOLO cubrimos el seguro del vehículo en nuestra cotización. Sus pertenencias en el auto no forman parte de la cotización del seguro. Tenga en cuenta que el seguro de estas pertenencias en el vehículo no está en su contrato vinculante. Cualquier daño a las pertenencias dentro del automóvil no será responsabilidad de Rates Auto Transport.'
    },
    { 
      title: '¿Debo estar presente todo el tiempo?',
      content: 'No, no tienes que estar presente durante toda la duración del proceso de recogida o entrega del envío. Sin embargo, recomendamos encarecidamente tener a alguien que sea mayor de edad disponible durante la carga o descarga del vehículo.'
    },
    { 
      title: '¿Puede enviar su coche con cosas dentro?',
      content: '¡Sí! Los clientes no siempre tienen tiempo para sacar sus pertenencias de su vehículo antes del inicio del servicio, por lo que a menudo transportamos coches con pequeñas pertenencias dentro. Entendemos y queremos hacer nuestra parte para que nuestros clientes se sientan cómodos. Nuestros transportistas de confianza y profesionales transportarán su vehículo sin ninguna pérdida. Tenga en cuenta que el seguro de estas posesiones en el vehículo no está en su contrato vinculante. Cualquier daño a las pertenencias dentro del coche no será responsabilidad de Rates Auto Transport. Por favor, retire cualquier pertenencia colocada en el asiento del conductor.'
    },
    { 
      title: '¿Cómo se calcula la tarifa de transporte de su vehículo?',
      content: 'Nos gusta mantener las cosas eficientes y directas. Pedimos a nuestros clientes que proporcionen detalles básicos sobre sus vehículos, como el tamaño, el peso, la distancia entre la recogida y la entrega, y la elección entre el transporte de vehículos abierto o cerrado. Al hacerlo, podemos evaluar con precisión las tarifas de transporte del vehículo en consecuencia. A diferencia de otras compañías de transporte, no solicitamos tarifas adicionales.'
    },
    { 
      title: '¿Qué pasa si quiero pagar con tarjeta de crédito?',
      content: 'Aceptamos diversas formas de pago que van desde tarjetas de crédito hasta PayPal. Los clientes pueden pagar en efectivo contra entrega (COD), pero se requiere un pago de depósito para asegurar su servicio de transporte.'
    },
    { 
      title: '¿Qué pasa si mi coche es pesado? ¿Eso resulta en un presupuesto de entrega más alto?',
      content: 'Calculamos nuestras tarifas de acuerdo con el tamaño y el peso del vehículo sin cargos adicionales al cliente.'
    },
    { 
      title: '¿Hay un pago inicial?',
      content: 'No, no hay tarifas ocultas ni cargos por consultas. Sin embargo, si opta por pagar en efectivo contra entrega (COD), se le requerirá hacer un depósito hacia el servicio de transporte seleccionado para asegurar su reserva.'
    }
  ];

  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const mitad = Math.ceil(preguntasFrecuentes.length / 2);
  const primeraMitad = preguntasFrecuentes.slice(0, mitad);
  const segundaMitad = preguntasFrecuentes.slice(mitad);

  return (
    <div className="faq-section" id='preguntas'>
      <Container>
        <h1>PREGUNTAS FRECUENTES</h1>
        <p><a href="tel:18704556742" style={{ color: '#8FB93E', textDecoration: 'none' }}>¿Tienes dudas? Llámanos al (1) 870-455-6742</a></p>
        <Row>
          <Col md={6}>
            <Accordion activeKey={activeKey} className='accordion'>
              {primeraMitad.map((item, index) => (
                <Accordion.Item eventKey={index.toString()} key={index} className='accordion'>
                  <Accordion.Header onClick={() => handleToggle(index.toString())} className='accordion'>
                    {item.title}
                  </Accordion.Header>
                  <Accordion.Body className='text-white'>
                    {item.content}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
          <Col md={6}>
            <Accordion activeKey={activeKey} className='accordion'>
              {segundaMitad.map((item, index) => (
                <Accordion.Item eventKey={(index + mitad).toString()} key={index + mitad} className='accordion'>
                  <Accordion.Header onClick={() => handleToggle((index + mitad).toString())} className='accordion'>
                    {item.title}
                  </Accordion.Header>
                  <Accordion.Body className='text-white'>
                    {item.content}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Preguntas;
