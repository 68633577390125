import moment from 'moment';
import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useFetchPost from '../hook/useFetchPost';

const blogContext = createContext();

export const BlogProvider = ({ children }) => {
    const { data, error, loading } = useFetchPost();
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        if (data && !error && !loading ) {
            const postsWithDate = data.map((post) => ({
                ...post,
                date: moment(post.date, 'YYYY-MM-DD'),
            }));

            const postsWithDateSorted = postsWithDate.sort((a, b) => b.date - a.date);
            setPosts(postsWithDateSorted);
        }
    }, [data, error, loading]);

    const findById = useCallback((postId) => {
        const post = posts.find((post) => post.id === postId);
        return post;
    }, [posts]);

    const value = useMemo(() => ({
        data,
        error,
        loading,
        findById,
        posts,
    }), [data, error, loading, findById, posts]);

    return (
        <blogContext.Provider value={value}>
            {children}
        </blogContext.Provider>
    );
}

BlogProvider.propTypes = {
    children: PropTypes.node
}

export const useBlogContext = () => {
    return useContext(blogContext);
}