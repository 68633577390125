const logos = {
    logo_nomanejes: { src: "/images/banner/logo_nomanejes.svg", alt: "Logo Nomanejes" },
    logo_puerto_rico_car_transfer: { src: "/images/banner/logo_puertoricocartransfer.svg", alt: "Logo Puerto Rico Car Transfer" },
    logo_carro_a_centro_america: { src: "/images/banner/logo_carrosacentroamerica.svg", alt: "Logo Carro a Centro America" },
    logo_pr_autotransport: { src: "/images/banner/logo_prautotransport.svg", alt: "Logo PR Autotransport" },
    logo_carmoversus: { src: "/images/banner/logo_carmoversus.svg", alt: "Logo Carmoversus" },
    logo_shipshippersusa: { src: "/images/banner/logo_shipshippersusa.svg", alt: "Logo Shipshippers USA" },
    logo_ratesautotransport: { src: "/images/banner/logo_ratesautotransport.svg", alt: "Logo Rates Autotransport" },
    logo_tucarroporusa: { src: "/images/banner/logo_tucarroporusa.svg", alt: "Logo Tu Carro Por Usa" },
    logo_tcpamerica: { src: "/images/banner/logo_tcpamerica.svg", alt: "Logo TCP America" },
}

const logos_ally = {
    logo_ally_centraldispatch: { src: "/images/banner/logo_ally_centraldispatch.svg", alt: "Logo Ally Central Dispatch" },
    logo_ally_seaboard: { src: "/images/banner/logo_ally_seaboardmarine.svg", alt: "Logo Ally Seaboard" },
    logo_ally_trailerbridge: { src: "/images/banner/logo_ally_trailerbridge.svg", alt: "Logo Ally Trailer Bridge" },
    logo_crowley: { src: "/images/banner/logo_ally_crowley.svg", alt: "Logo Crowley" },
    logo_interport: { src: "/images/banner/logo_ally_interport.svg", alt: "Logo Interport" },
    logo_cmacgm: { src: "/images/banner/logo_ally_cmacgm.svg", alt: "Logo CMACGM" },
}

const logos_payments = {
    logo_visa: { src: "/images/banner/logo_payment_visa.svg", alt: "Logo Visa" },
    logo_mastercard: { src: "/images/banner/logo_payment_mastercard.svg", alt: "Logo Mastercard" },
    logo_americanexpress: { src: "/images/banner/logo_payment_americanexpress.svg", alt: "Logo Paypal" },
}

export default function BannerGarlasLLCLogosMobile() {
    return (
        <div className="d-flex flex-column gap-5 w-100 container py-5">
            <div className="logo-slider overflow-hidden position-relative mb-2">
                <div className="logo-track d-inline-block" id="logo-container">
                    {
                        Object.values(logos).map((logo, index) => (
                            <img key={index} src={logo.src} alt={logo.alt} className="mx-2 logo-slider-img" />
                        ))
                    }

                    {
                        Object.values(logos).map((logo, index) => (
                            <img key={index} src={logo.src} alt={logo.alt} className="mx-2 logo-slider-img" />
                        ))
                    }
                </div>
            </div>
            <div className="logo-slider overflow-hidden position-relative mb-3">
                <div className="logo-track d-inline-block" id="logo-ally-container">
                    {
                        Object.values(logos_ally).map((logo, index) => (
                            <img key={index} src={logo.src} alt={logo.alt} className="mx-2 logo-slider-img" />
                        ))
                    }
                    {
                        Object.values(logos_ally).map((logo, index) => (
                            <img key={index} src={logo.src} alt={logo.alt} className="mx-2 logo-slider-img" />
                        ))
                    }
                </div>
            </div>
            <div className="d-flex gap-3 justify-lg-content-between justify-content-center align-items-center">
                <img src={logos_payments.logo_visa.src} alt="Visa" height={"20"} />
                <img src={logos_payments.logo_mastercard.src} alt="Mastercard" height={"34"} />
                <img src={logos_payments.logo_americanexpress.src} alt="American Express" height={"30"} />
            </div>
            <div className="text-center">
                <p className="fs-5">Comunícate con alguna de nuestras empresas HOY.</p>
            </div>
        </div>
    );
}