import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import '../Review.css';

const Review = () => {
  return (
    <div className="review-section py-5">
      <Container className='py-5'>
        <div className="main-review-card mt-5">
          <div className="quote-icon">“</div>

          <Row className="mt-5">
            <Col md={3} className='px-3 mt-2'>
          <h1>Opiniones</h1>
          <p>
            <strong>Tu Carro por USA</strong> hizo que transportar mi auto de California a Nueva York fuera muy sencillo! Profesionales, eficientes y mi vehículo llegó en buen estado hasta el lugar de destino.
          </p>
          <p>tucarroporusa.com</p>
          </Col>
            <Col md={3} className='mb-5'>
              <Card className="review-card2">
                <Card.Img variant="top" src="images/claudia.jpg" />
                <Card.Body className='reviews-row'>
                  <div className="small-quote-icon">“</div>
                  <Card.Title>Jessica Cuevas</Card.Title>
                  <Card.Text>
                    ¡Excelente servicio! Recogieron mi auto ayer con mis cosas y hoy ya las tiene mi hija en perfecto estado. Comunicación constante y atención impecable. ¡Altamente recomendado!
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className='mb-5'>
              <Card className="review-card2">
                <Card.Img variant="top" src="images/kevin.jpg" />
                <Card.Body className='reviews-row'>
                  <div className="small-quote-icon">“</div>
                  <Card.Title>Carlos Carballo</Card.Title>
                  <Card.Text>
                    Mi carro me llegó intacto. Esta empresa se comunicó durante todo el proceso. Gracias.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className='mb-5'>
              <Card className="review-card2">
                <Card.Img variant="top" src="images/monica.jpg" />
                <Card.Body className='reviews-row'>
                  <div className="small-quote-icon">“</div>
                  <Card.Title>Melanie Gimenez</Card.Title>
                  <Card.Text>
                    Muy buen servicio, rápido y seguro. Solo tuve que dar un pequeño anticipo para que me recogieran el carro y el excedente lo pagué cuando me lo entregaron a satisfacción.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Review;
