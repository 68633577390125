import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../Footer.css'; // Asegúrate de crear y vincular este archivo CSS

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <Container>
        <Row className="pb-2 pt-4  d-none d-md-flex " >
          <Col md={3}>
            <div className="footer-logo">
              <img src="/images/logo.png" alt="Logo" width="187px" height="70px" />
            </div>
            <ul className="list-unstyled px-3">
              <li><a href="#quienes">Nosotros</a></li>
              <li><a href="#preguntas">Preguntas frecuentes</a></li>
              <li><a href="/terms">Términos y condiciones</a></li>
              {/* <li><a href="#privacy">Política de privacidad</a></li> */}
            </ul>
          </Col>
          <Col md={3}className='mt-4'>
            <h5>Contacto</h5>
            <p><a href="tel:18704556742" style={{textDecoration: 'none',  color: '#FFF'}}>Teléfono: 1-870-455-6742</a></p>
          </Col>
          <Col md={6} className='mt-4'>
            <h5>Nuestros métodos de pago seguros y confiables</h5>
            <div className="payment-methods">
              <img src="/images/master1.png" alt="Payment Method 1" />
              <img src="/images/Visa-Logo.png" alt="Payment Method 2" />
              <img src="/images/paypal1.png" alt="Payment Method 3" />
              <img src="/images/zelle.png" alt="Payment Method 4" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-center pb-4 mt-2">
            <p>tucarroporusa.com ©{currentYear} - Todos los derechos reservados</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
