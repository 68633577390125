import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const Truck = () => {
  return (
    <div className='mt-5'>
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col md={7} className='d-flex justify-content-end px-3'>
              <img
                src="images/truck.png" 
                alt="truck"
                className="img-fluid"
              />
            </Col>
            <Col md={5}>
              <h1 className='text-g300 px-3 font-jura'>El TRANSPORTE DE AUTO EFICENTE Y SEGURO</h1>
              <p className='px-3'>Te ofrecemos la confianza que mereces para que tu vehiculo viaje de manera segura.</p>
            <a href="tel:18704556742" style={{ width: 'fit-content'}} className="btn btn-primary custom-button ms-3 py-2 d-none d-md-block">Llamanos (1) 870-455-6742</a>
            <a href="tel:18704556742"  className="btn responsive-button ms-3 py-2 d-block d-md-none">Llamanos (1) 870-455-6742</a>
            </Col>
          </Row>
        </Container>
    </div>
  );
};

export default Truck;
