import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import QuoteHome from "./QuoteHome";

const Home = () => {
  const backgroundImage = "images/fondo.png"; // Cambia esto a la ruta real de tu imagen

  return (
    <div
      className="home-background"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="overlay " id="home">
        <Container className="h-100">
          <Row className="mt-5 d-flex align-items-center">
            <Col md={6} className="hero-heading">
              <h1
                style={{ fontWeight: "bolder" }}
                className="text-white px-3 font-jura"
              >
                OBTÉN <span className="text-green">COTIZACIÓN INMEDIATA</span>{" "}
                PARA EL ENVÍO DE TU VEHÍCULO<br></br>
              </h1>
              <h5 className="text-g200 px-3" style={{fontWeight: '400'}}>
                Servicio confiable adaptado a tus necesidades
              </h5>
            </Col>

            <Col md={6} className="px-3 mt-5">
              <QuoteHome />
            </Col>
          </Row>
        </Container>
      </div>
      <div className=" vectores"></div>
    </div>
  );
};

export default Home;
