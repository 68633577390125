import { Route, Routes } from "react-router-dom";
import BlogPage from './BlogPage';
import Post from "./Post";
import { BlogProvider } from '../../context/useBlogContext';


const BlogRoutes = () => {
  return (
    <BlogProvider>
      <Routes>
        <Route path="/" element={<BlogPage />} />
        <Route path="/post/:id" element={<Post />} />
      </Routes>
    </BlogProvider>
  );
};

export default BlogRoutes;