const logos = {
    logo_nomanejes: { src: "/images/banner/logo_nomanejes.svg", alt: "Logo Nomanejes" },
    logo_puerto_rico_car_transfer: { src: "/images/banner/logo_puertoricocartransfer.svg", alt: "Logo Puerto Rico Car Transfer" },
    logo_carro_a_centro_america: { src: "/images/banner/logo_carrosacentroamerica.svg", alt: "Logo Carro a Centro America" },
    logo_pr_autotransport: { src: "/images/banner/logo_prautotransport.svg", alt: "Logo PR Autotransport" },
    logo_carmoversus: { src: "/images/banner/logo_carmoversus.svg", alt: "Logo Carmoversus" },
    logo_shipshippersusa: { src: "/images/banner/logo_shipshippersusa.svg", alt: "Logo Shipshippers USA" },
    logo_ratesautotransport: { src: "/images/banner/logo_ratesautotransport.svg", alt: "Logo Rates Autotransport" },
    logo_tucarroporusa: { src: "/images/banner/logo_tucarroporusa.svg", alt: "Logo Tu Carro Por Usa" },
    logo_tcpamerica: { src: "/images/banner/logo_tcpamerica.svg", alt: "Logo TCP America" },
}

const logos_ally = {
    logo_ally_centraldispatch: { src: "/images/banner/logo_ally_centraldispatch.svg", alt: "Logo Ally Central Dispatch" },
    logo_ally_seaboard: { src: "/images/banner/logo_ally_seaboardmarine.svg", alt: "Logo Ally Seaboard" },
    logo_ally_trailerbridge: { src: "/images/banner/logo_ally_trailerbridge.svg", alt: "Logo Ally Trailer Bridge" },
    logo_crowley: { src: "/images/banner/logo_ally_crowley.svg", alt: "Logo Crowley" },
    logo_interport: { src: "/images/banner/logo_ally_interport.svg", alt: "Logo Interport" },
    logo_cmacgm: { src: "/images/banner/logo_ally_cmacgm.svg", alt: "Logo CMACGM" },
}

const logos_payments = {
    logo_visa: { src: "/images/banner/logo_payment_visa.svg", alt: "Logo Visa" },
    logo_mastercard: { src: "/images/banner/logo_payment_mastercard.svg", alt: "Logo Mastercard" },
    logo_americanexpress: { src: "/images/banner/logo_payment_americanexpress.svg", alt: "Logo Paypal" },
}

export default function BannerGarlasLLCLogos() {
    return (
        <div className="d-flex flex-column w-100 justify-content-between">
            {/* Primera fila */}
            <div className="row no-gutters align-items-center" style={{ height: "50px" }}>
                {Object.entries(logos).slice(0, 5).map(([key, logo]) => (
                    <div key={key} className="col text-center">
                        <img
                            src={logo.src}
                            alt={logo.alt}
                            className={`img-fluid logo-img`}
                        />
                    </div>
                ))}
            </div>
            <div className="d-flex row align-items-center" >
                {Object.entries(logos).slice(5).map(([key, logo]) => (
                    <div key={key} className={`text-center col`}>
                        <img
                            src={logo.src}
                            alt={logo.alt}
                            className={`img-fluid w-auto h-100 logo-img`}
                        />
                    </div>
                ))}
            </div>

            {/* Segunda fila */}
            <div className="row no-gutters align-items-center mb-5" style={{ height: "40px" }}>
                {Object.entries(logos_ally).map(([key, logo]) => (
                    <div key={key} className="col text-center">
                        <img
                            src={logo.src}
                            alt={logo.alt}
                            className={`img-fluid logo-img ${ key === "logo_centraldispatch" ? "logo-large" : ""}`}
                        />
                    </div>
                ))}
            </div>

            {/* Métodos de pago */}
            <div className="d-flex justify-content-center gap-3 w-100 align-items-center">
                {Object.values(logos_payments).map((logo, index) => (
                    <img key={index} src={logo.src} height="30px" alt={logo.alt} />
                ))}
                <div className="d-flex w-100 justify-content-end">
                    <p className="text-center m-0" style={{ fontSize: "1.1em" }}>
                        Comunícate con alguna de nuestras empresas HOY.
                    </p>
                </div>
            </div>
        </div>
    )
}