import BannerGarlasLLCLogos from './BannerGarlasLLCLogos';
import BannerGarlasLLCLogosMobile from './BannerGarlasLLCLogosMobile';


export default function BannerGarlasLLC() {
    return (
        <section className="container px-3 mx-auto py-5 w-100" id="banner">
            <div className="d-flex flex-column gap-lg-5 gap-4 w-100">
                <div className='d-flex justify-content-center justify-content-lg-start'>
                    <img src="/images/banner/garlasllc-banner-tittle.svg" alt="Garlas LLC" height="36" width="auto" className='align-self-start' />
                </div>

                <div className='d-flex w-100 flex-column flex-lg-row'>
                    <div className='d-flex flex-column gap-2 box-border width-side-right' >
                        <h3 className='banner-garlas-llc-heading text-center text-lg-start'>
                            Tu aliado más grande en los <wbr /> Estados Unidos para transportar <wbr /> tu vehiculo
                        </h3>
                        <div className='d-flex flex-column justify-content-between rounded-4 py-2 px-3 bg-green-primary w-100 gap-3'>
                            <p className='banner-garlas-llc-card-paragraph' >Somos una red de empresas <wbr /> especializadas en el transporte de tu <wbr /> auto, moto, camión, bote, yate etc.</p>
                            <p className='banner-garlas-llc-card-paragraph-2 d-flex justify-content-center justify-content-lg-end'>Tu dinos!</p>
                        </div>
                    </div>
                    <div className="d-none d-lg-flex width-side-left">
                        <BannerGarlasLLCLogos />
                    </div>
                    <div className="d-flex d-lg-none w-100 overflow-hidden">
                        <BannerGarlasLLCLogosMobile />
                    </div>
                </div>
            </div>
        </section>
    )
}